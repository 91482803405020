<template>
  <div class="g">
    <FormLogin />
  </div>
</template>
<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    FormLogin
  },
  mounted() {
    let auth = Auth.checkAuth();
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWI4ZWJmZDM5NDQ3MWQ0NjY5YzViZTQiLCJfZW1haWwiOiIiLCJfbmFtZSI6IteY16HXmCIsIl9yb2xlIjoibWFuYWdlciIsIl9zdGF0dXMiOnRydWUsIl9leHBpcmVzSW4iOiIxLzMxLzIwMjQsIDc6MzQ6MzTigK9QTSIsImlhdCI6MTcwNjcwMDg3NCwiZXhwIjoxNzA2NzExNjc0fQ.e6GjrN3ISPB13ES9AwSlrv5JBMaYQg8iwcvOZAb5ecE"
    // localStorage.setItem("token", token)
    if (auth) {
      this.$router.replace({ name: "home"})
    }
  },
}
</script>
<style>
.g {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #34a5e6;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: pages_colorIn__r4Ogf 2.5s ease-out;
}

@keyframes pages_colorIn__r4Ogf {
  0% {
    filter: grayscale(1);
  }

  100% {
    filter: grayscale(0);
  }
}
</style>