<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="6" style="max-width: 500px;">
        <v-card class="pa-4" style="box-shadow: 0 0 10px #fff;">
          <div class=" d-flex justify-center">
            <span style="font-size: xx-large; font-weight: bold; color:#1e517b">חלוקת מוצרי חלב</span>
          </div>
          <div>
            <div class=" d-flex justify-center">
              <span style="font-size: x-large; color:#1e517b">אזור אישי</span>
            </div>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field v-model="username" label="שם משתמש" type="text" :rules="[v => !!v || 'שדה חובה']"
                placeholder="שם משתמש" required></v-text-field>
              <v-text-field v-model="password" label="סיסמה" type="password" :rules="[v => !!v || 'שדה חובה']"
                placeholder="סיסמה" required></v-text-field>
              <!-- <span @click="forgotPassword"> -->
                <span>
                <a>שכחתי סיסמה</a>
              </span>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="login" color="#635ddb">
                <span class="white--text px-8">כניסה</span>
              </v-btn>
            </v-card-actions>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {

  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    username: "",
    password: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),
  methods: {

    async login() {
      if (this.$refs.form.validate()) {

        this.progressShow = true
        let MyJSON = JSON.stringify({ username: this.username, password: this.password });
        let api = process.env.VUE_APP_BASE_URL + "/auth/login"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status === 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 429) {
            this.showSnackBar("יותר מדי נסיונות כניסה", "red");
          } else if (response.status === 200) {
            this.showSnackBar("התחברת בהצלחה!", "green");
            localStorage.setItem('token', jsonObject.token);
            this.$router.replace({ name: "home"})
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },


    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    // async forgotPassword() {
    //   if (this.email === "") {
    //     this.showSnackBar("יש להזין אימייל לצורך איפוס הסיסמה", "red");
    //     return
    //   }
    //   this.progressShow = true;
    //   let MyJSON = JSON.stringify({ email: this.email });
    //   let api = process.env.VUE_APP_BASE_URL + "/auth/forgotPassword"

    //   try {
    //     const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
    //     this.progressShow = false;
    //     if (response.status === 401) {
    //       this.showSnackBar("שגיאה: אימייל לא קיים", "red");
    //     } else if (response.status === 429) {
    //       this.showSnackBar("יותר מדי נסיונות כניסה", "red");
    //     } else if (response.status === 400) {
    //       this.showSnackBar("שגיאת שרת", "red");
    //     } else if (response.status === 200) {
    //       this.showSnackBar("נשלח מייל לאיפוס הסיסמה", "green");
    //     }
    //   } catch (error) {
    //     this.progressShow = false;
    //     this.showSnackBar("Error Login: " + error, "red");
    //   }
    // },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
  watch: {
    $route() {
      document.title = 'כניסה | משמחי לב'
    },
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    document.title = 'כניסה | משמחי לב'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
@media (max-width: 500px) {
  .d-flex.justify-space-around {
    flex-direction: column;
    align-items: center;
  }

  .d-flex.justify-space-around>* {
    margin-bottom: 1rem;
  }

  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }

  .d-flex.flex-wrap>* {
    flex-basis: 100%;
    margin-bottom: 10px;
    /* Add margin between lines */
  }

  h1 {
    font-size: 1.2em;
  }
}

@media (min-width: 501px) {
  h1 {
    font-size: 1.5em;
  }
}

.filtered-calls-blocked {
  font-weight: bold;
}
</style>
