<template>
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' xl='11'>
        <v-data-table id="table1" :headers="headers" :items="customers" :search="search" class="elevation-4" show-expand
          :expanded.sync="expanded" item-key="_id"
          :footer-props="{ 'items-per-page-options': [50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length"
              style="background-color: #f5f5f5; box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.2);">
              <v-container>
                <v-row style="padding: 10px; display: flex; justify-content: center;">
                  <v-col cols="12" md="8">
                    <div style="max-height: 450px; overflow-y: auto; background-color: white; border-radius: 15px;">
                      <v-data-table :headers="ordersHeaders" :items="ordersData" sort-by="index" class="elevation-0"
                        hide-default-footer :items-per-page="ordersData.length">
                        <template v-slot:item.index="{ index }">
                          <tr>
                            <td>{{ index + 1 }}</td>
                          </tr>
                        </template>
                        <template v-slot:no-data>
                          <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                              <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                            </v-col>
                          </v-row>
                          <div class="ma-5">
                            <span class="text-center"
                              style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                              לא נמצאו הזמנות
                            </span>
                          </div>
                        </template>

                        <!-- <template v-slot:item.actions="{ item, index }">
                          <v-tooltip bottom v-if="item.payment_status !== 'שולם'">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="#0e81c4" medium class="mr-2 me-3"
                                @click="openEditDialog(item, index)">
                                mdi-pencil
                              </v-icon>
                            </template>
                            <span>עריכה</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="item.payment_status !== 'שולם'">

                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="#0e81c4" medium class="me-3"
                                @click="openDeleteDialog(item, index)">
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>מחיקה</span>
                          </v-tooltip>
                        </template> -->
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
                <v-row style="padding: 10px; display: flex; justify-content: center;">
                  <v-col cols="12" md="8">
                    <div>
                      <span style="font-size: larger; font-weight: bold;">סה"כ לתשלום: </span>
                      <span style="font-size: larger; font-weight: bold;">{{ totalAmount }} ₪</span>
                      <span style="font-size: larger; font-weight: bold; margin-right: 50px;">סטטוס תשלום: </span>
                      <span style="font-size: larger; font-weight: bold;">{{ item.payment_status }}</span>
                    </div>
                    <div v-if="item.payment_status === 'שולם'" style="margin-top: 10px;">
                      <div style="margin-top: 10px;">
                        <span style="font-size: larger; font-weight: bold;">פרטי תשלום:</span>
                      </div>
                      <div style="display: flex;">
                        <div style="margin-left: 20px;">
                          <span style="font-size: larger; font-weight: bold;">סוג תשלום: </span>
                          <span style="font-size: larger;">כרטיס אשראי</span>
                        </div>
                        <div style="margin-left: 20px;">
                          <span style="font-size: larger; font-weight: bold;">סכום: </span>
                          <span style="font-size: larger;">{{ totalPayment }} ₪</span>
                        </div>
                        <div style="margin-left: 20px;">
                          <span style="font-size: larger; font-weight: bold;">תאריך תשלום: </span>
                          <span style="font-size: larger;">{{ payDate }}</span>
                        </div>
                        <div style="margin-left: 20px;">
                          <span style="font-size: larger; font-weight: bold;">מספר כרטיס: </span>
                          <span style="font-size: larger;">{{ last_digits }}</span>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #34a5e6;">לקוחות</span>
            </div>
            <div class="divider"></div>

            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <!-- <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="importDialog = true">
                          mdi-file-upload
                        </v-icon>
                      </template>
                      <span>ייבוא מאקסל</span>
                    </v-tooltip>
                  </v-btn> -->
                  <v-btn small color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="refreshDetails()">
                          mdi-refresh
                        </v-icon>
                      </template>
                      <span>רענן תוצאות</span>
                    </v-tooltip>
                  </v-btn>

                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details
                  @keyup.enter="getSearchCustomer">
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ index }">
            <tr>
              <td>{{ (page - 1) * itemsPerPage + index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#0e81c4" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#0e81c4"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#0e81c4" dark style="margin-bottom: 10px;" @click="getCustomers()">
              רענן
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :itemToEdit="editedItem" :formTitle="formTitle"
      :stations="stations" @customerAdded="newCustomerSaved" @customerEdited="editedCustomerSaved"> </AddEditDialog>

    <ImportFromExcelDialog v-model="importDialog" v-if="importDialog" @ImportExcel="ImportExcel"
      @exportExampleExcel="donwloadExampleExcel"></ImportFromExcelDialog>

    <ImportFinishDialog v-model="importFinishDialog" v-if="importFinishDialog" :importedSuccess="importedSuccess"
      :importedFailed="importedFailed" @importedSuccessAdded="importedSuccessAdded"></ImportFinishDialog>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @customerDeleted="customerDeleted">
    </AlertDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/customers/dialogs/AddEditDialog'
import AlertDialog from '@/components/customers/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";
import ImportFromExcelDialog from '@/components/customers/dialogs/ImportExcel'
import ImportFinishDialog from '@/components/customers/dialogs/ImportMessageDialog'
import axios from "axios";

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar,
    ImportFromExcelDialog,
    ImportFinishDialog
  },
  data: () => ({
    importDialog: false,
    importFinishDialog: false,
    importedFailed: [],
    importedSuccess: [],
    progressSave: false,
    formTitle: "",
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם', value: 'name' },
      { text: 'טלפון', value: 'phone' },
      { text: 'נקודת חלוקה', value: 'shop_station' },
      { text: 'מספר הזמנה', value: 'order_number' },
      { text: 'מוצרים שהוזמנו', value: 'ordered_quantity' },
      { text: 'סטטוס תשלום', value: 'payment_status' },
      { text: 'סטטוס', value: 'status' },
      { text: 'נוצר בתאריך', value: 'createdAt' },
      { text: 'עודכן בתאריך', value: 'updatedAt' },
      { text: 'פעולות', value: 'actions', sortable: false },
    ],
    ordersHeaders: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם מוצר', value: 'product_name' },
      { text: 'כמות', value: 'quantity' },
      { text: 'סכום לתשלום', value: 'price' },
      { text: 'נוצר בתאריך', value: 'createdAt' },
      { text: 'פעולות', value: 'actions', sortable: false },
    ],
    stations: [],
    itemsPerPage: 50,
    alertDialog: false,
    addEditDialog: false,
    customers: [],
    expanded: [],
    ordersData: [],
    totalAmount: 0,
    totalPayment: 0,
    payDate: null,
    last_digits: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    email: "",
    search: "",
    page: 1, // Current page
    totalCustomers: 0, // Total number of customers for pagination controls
    sortBy: "",
    sortDesc: false, // default sort direction
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    computedHeaders() {
      return this.headersList.filter(header => header.status);
    },
  },
  methods: {

    async getCustomers() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        this.customers = [];
        const customerJSON = JSON.stringify({ page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });
        let api = process.env.VUE_APP_BASE_URL + "/customers/get_customers";
        const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.customers = jsonObject.customers;
          // this.totalCustomers = jsonObject.total;
          // console.log(this.customers);
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    async getStationsList() {
      try {
        this.stations = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/stations/get_stations_list";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.stations = jsonObject;
          this.getCustomers();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.getCustomers();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    // async getSearchCustomer() {
    //   try {
    //     if (!this.search) {
    //       this.getCustomers();
    //       return
    //     }
    //     this.progressShow = true;
    //     let token = localStorage.getItem("token");
    //     const customerJSON = JSON.stringify({ content: this.search.trim()});
    //     this.customers = [];
    //     let api = process.env.VUE_APP_BASE_URL + "/customers/get_search_customers";
    //     const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
    //     const jsonObject = await res.json();
    //     this.progressShow = false;
    //     if (res.status === 400) {
    //       this.showSnackBar("שגיאה בקבלת נתונים", "red");
    //     } else if (res.status === 200) {
    //       this.customers = jsonObject;
    //       this.formatDetails();
    //     }
    //   } catch (error) {
    //     this.progressShow = false;
    //     this.showSnackBar("Error get subscribes list: " + error, "red");
    //   }
    // },
    formatDetails() {
      this.customers = this.customers.map((customer) => {
        const foundStation = this.stations.find(station => station.name === customer.shop_station);
        console.log(this.stations);
        const stationHebrewName = foundStation.hebrewName;
        return {
          ...customer,
          status: customer.status ? "פעיל" : "מושבת",
          payment_status: customer.payment_status === "in_progress" ? "בתהליך" : customer.payment_status === "paid_manually" ? "שולם ידנית" : "שולם",
          createdAt: Utils.getFormatDate2(customer.createdAt),
          updatedAt: Utils.getFormatDate2(customer.updatedAt),
          shop_station: stationHebrewName
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async donwloadExampleExcel() {

      const originalHeaders = JSON.parse(JSON.stringify(this.headersList));

      this.headersList = this.headersList.filter(header => header.value !== "order_details.ordered_quantity"
        && header.value !== "index" && header.value !== "createdAt" && header.value !== "updatedAt");

      this.headersList.forEach(header => {
        header.status = true;
      })


      // Create a copy of the headers with English text
      const englishHeaders = [
        // { text: '#', value: "index" },
        { text: 'family', value: 'family' },
        { text: 'father_name', value: 'father_name' },
        { text: 'mother_name', value: 'mother_name' },
        { text: 'father_id', value: 'father_id' },
        { text: 'mother_id', value: 'mother_id' },
        { text: 'phone1', value: 'phone1' },
        { text: 'phone2', value: 'phone2' },
        { text: 'phone3', value: 'phone3' },
        { text: 'phone4', value: 'phone4' },
        { text: 'email', value: 'email' },
        { text: 'address', value: 'address' },
        { text: 'city', value: 'city' },
        { text: 'apartment', value: 'apartment' },
        { text: 'community', value: 'community' },
        { text: 'status', value: 'status' },
        { text: 'mode', value: 'mode' },
        { text: 'powerLink_id', value: 'powerLink_id' },
        { text: 'possible_quantity', value: 'order_details.possible_quantity' },
        // { text: 'ordered_quantity', value: 'order_details.ordered_quantity' },
        { text: 'shop_station', value: 'order_details.shop_station' },
        { text: 'day_to_come', value: 'order_details.day_to_come' },
        { text: 'hour_to_come', value: 'order_details.hour_to_come' },
        // { text: 'createdAt', value: 'createdAt' },
        // { text: 'updatedAt', value: 'updatedAt' },
      ];

      this.$nextTick(() => {
        // Clone the table
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        // // Replace the table headers with the English headers in the cloned table
        const tableHeaders = clonedTable.getElementsByTagName('th');
        for (let i = 0; i < englishHeaders.length; i++) {
          console.log(englishHeaders[i].text);
          console.log(tableHeaders[i].textContent);
          tableHeaders[i].textContent = englishHeaders[i].text;
        }

        // Now the clonedTable only contains the desired header columns
        TableToExcel.convert(clonedTable, {
          name: 'exampleCustomersFile.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        this.headersList = originalHeaders;

      })
    },
    async downloadExcel() {
      const originalHeaders = JSON.parse(JSON.stringify(this.headers));

      this.headers.forEach(header => {
        header.status = true;
      })
      // this.setItemsPerPage(-1); // Set to display all items
      // Wait for the next tick to ensure the table is updated
      this.$nextTick(() => {
        // Clone the table
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        // Replace the table headers with the English headers in the cloned table
        const tableHeaders = clonedTable.getElementsByTagName('th');

        // Remove the first header
        tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
        // Remove the last header
        tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);

        // Remove the first and last columns from the cloned table
        const tableRows = clonedTable.getElementsByTagName('tr');
        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].getElementsByTagName('td');
          if (tableCells.length > 0) {
            tableCells[0].parentNode.removeChild(tableCells[0]); // Remove the first cell
            tableCells[tableCells.length - 1].parentNode.removeChild(tableCells[tableCells.length - 1]); // Remove the last cell
          }
        }

        TableToExcel.convert(clonedTable, {
          name: 'customers.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        // Optionally, reset the itemsPerPage after export
        // this.setItemsPerPage(20); // Or any default value
        this.headersList = originalHeaders;
      });
    },
    refreshDetails() {
      this.getCustomers();
    },
    openEditDialog(item, index) {
      console.log(33333333);
      this.editedItem = item;
      this.formTitle = "ערוך לקוח"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף לקוח"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newCustomerSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.customers.push(item);

    },
    editedCustomerSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      console.log(77777);
      const index = this.customers.findIndex(customer => customer._id === item._id);
      Object.assign(this.customers[index], item);
    },
    customerDeleted(item, text, color) {
      if (item === "") {
        this.showSnackBar(text, color)
        return
      }
      const index = this.customers.findIndex(product => product._id === item._id);
      this.customers.splice(index, 1);
      this.showSnackBar(text, color)
    },
    async fetchCustomerOrders(customerId) {
      console.log(customerId);
      // if (!this.ordersData[customerId]) { // Fetch only if not already fetched
      //   this.$set(this.ordersData, customerId, { loading: true, data: null });

      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        this.ordersData = [];

        let api = process.env.VUE_APP_BASE_URL + `/customers/get_customer_details?customerId=${customerId}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
          // this.$set(this.ordersData, customerId, { loading: false, data: [] });
        } else if (res.status === 200) {
          console.log(jsonObject);
          this.ordersData = jsonObject.orders;
          this.totalAmount = jsonObject.totalAmount;
          this.totalPayment = jsonObject.totalPayment;
          this.payDate = jsonObject.payDate;
          this.last_digits = jsonObject.last_digits;
          
          // // this.$set(this.ordersData, customerId, { loading: false, data: jsonObject });
          // console.log(this.ordersData);
        }
      } catch (error) {
        this.progressShow = false;
        // this.$set(this.ordersData, customerId, { loading: false, data: [] });
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
      // }
    },
    ImportExcel(file) {

      this.importedSuccess = [];
      this.importedFailed = [];

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          for (let i = 0; i < jsonData.length; i++) {
            const row = jsonData[i];
            let { family, father_name, mother_name, father_id, mother_id, phone1, phone2, phone3, phone4, email, address, city,
              apartment, community, status, powerLink_id, possible_quantity, shop_station, day_to_come, hour_to_come } = row;

            if (mother_id === undefined) {
              mother_id = ""
            }
            if (father_id === undefined) {
              father_id = ""
            }

            if (phone1 && !phone1.startsWith('0')) {
              phone1 = "0" + phone1;
            }
            if (phone2 && !phone2.startsWith('0')) {
              phone2 = "0" + phone2;
            }
            if (phone3 && !phone3.startsWith('0')) {
              phone3 = "0" + phone3;
            }
            if (phone4 && !phone4.startsWith('0')) {
              phone4 = "0" + phone4;
            }

            // Valid subscribe not empty
            if (!father_id && !mother_id) {
              console.log("error row is missing card_id");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            if (status && status === "פעיל") {
              status = true;
            } else if (status && status === "מושבת") {
              status = false;
            } else if (status && status === "לא פעיל") {
              status = false;
            } else {
              status = true;
            }

            const regex = /^\d+$/; // Only numeric characters
            const isValidFormat1 = regex.test(possible_quantity);
            if (possible_quantity && !isValidFormat1) {
              console.log("error format possible_quantity");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const isValidFormat3 = regex.test(hour_to_come);
            if (hour_to_come && !isValidFormat3) {
              console.log("error format hour_to_come");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const rowData = {
              family, father_name, mother_name, father_id, mother_id, phone1, phone2, phone3, phone4, email, address, city,
              apartment, community, status, powerLink_id, order_details: { possible_quantity, shop_station, day_to_come, hour_to_come }
            };

            this.importedSuccess.push(rowData)
          }

          this.importFinishDialog = true;

        } catch (error) {
          this.showSnackBar('Error parsing Excel file: ' + error, "red");
        }
      };
      reader.onerror = (e) => {
        this.showSnackBar('Error reading file: ' + e.target.error, "red");
      };
      reader.readAsArrayBuffer(file);

    },
    importedSuccessAdded() {
      this.showSnackBar('הלקוחות נוספו בהצלחה!', "green");
      this.getCustomers();
    },
  },
  watch: {
    expanded(newVal, oldVal) {
      if (newVal.length > oldVal.length) { // Check if a row has been expanded
        const newlyExpandedId = newVal.find(_id => !oldVal.includes(_id));
        this.fetchCustomerOrders(newlyExpandedId._id);
      }
    }
  },
  created() {
    this.getStationsList();
  },
}
</script>

<style scoped>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}</style>