<template>
  <div id="mainContainer">
    <v-row no-gutters>

      <div style="margin-top: 10px;">
        <div class="sectionLargeIcon largeServerIcon"></div>
        <div class="widget clearfix">
          <span class="title"
            style="font-size: 22px; margin-right: 15px;  font-weight: 600 !important; color: #183677;">דוחות</span>
          <div class="widget_inside" style="margin-top: 30px;">

            <v-btn @click="openSelected('reports1')" style="margin-left: 20px;">
              מוניטור הזמנות
            </v-btn>

            <!-- <v-btn @click="openSelected('reports2')" style="margin-left: 20px;">
              חפק אונליין
            </v-btn> -->

            <v-btn @click="openSelected('reports3')">
              דוח הכנסות 
            </v-btn>

            <FirstReportsComp v-if="reports1" />
            <SecondReportsComp v-if="reports2" />
            <ThirdReportsComp v-if="reports3" />
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import FirstReportsComp from '@/components/reports/FirstReportsComp'
import SecondReportsComp from '@/components/reports/SecondReportsComp'
import ThirdReportsComp from '@/components/reports/ThirdReportsComp'

export default {
  name: 'agent',
  components: {
    FirstReportsComp,
    SecondReportsComp,
    ThirdReportsComp
  },
  data: () => ({
    reports1: true,
    reports2: false,
    reports3: false,
  }),
  methods: {
    openSelected(reportsNum) {
      if (reportsNum === "reports1") {
        this.reports1 = true;
        this.reports2 = false;
        this.reports3 = false;
      } else if (reportsNum === "reports2"){
        this.reports1 = false;
        this.reports2 = true;
        this.reports3 = false;
      }else{
        this.reports1 = false;
        this.reports2 = false;
        this.reports3 = true;
      }
    },
  },
  created() {

  },
}
</script>
<style scoped></style>