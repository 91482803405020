<template>
    <v-dialog v-model="openMode" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" label="טלפון" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']"></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.order_number" label="מספר הזמנה" type="number"
                                    hide-spin-buttons :rules="[v => !!v || 'שדה חובה']"></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="6" >
                                <v-select :items="stations" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.shop_station" outlined dense label="נקודת חלוקה"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.status" outlined dense label="סטטוס"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                             <!-- <v-col cols="12" sm="6" v-if="itemEdited.payment_status !== 'paid'">
                                <v-select :items="paymentStatus" v-model="itemEdited.payment_status"
                                    :item-text="'hebrewName'" :item-value="'name'" hide-details outlined dense
                                    label="סטטוס תשלום" :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col> -->
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#0e81c4" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#0e81c4" text @click="formTitle === 'ערוך לקוח' ? saveEditedCustomer() : saveNewCustomer()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service.js'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        stations: Array,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        role:"",
        itemEdited: {
            _id: "",
            name: '',
            phone: "",
            shop_station: "",
            status: "",
            // payment_status: "",
        },
        status: [{ hebrewName: "פעיל", name: true }, { hebrewName: "מושבת", name: false }],
        // paymentStatus: [{ hebrewName: "בתהליך", name: "in_progress" }, { hebrewName: "שולם ידנית", name: "paid_manually" }],
    }),

    methods: {
        async saveNewCustomer() {

            if (this.$refs.form.validate()) {

                const customerJSON = JSON.stringify({ customer: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/customers/create_customer";
                    const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת לקוח" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("customerAdded", this.itemEdited, "הלקוח נוסף בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved customer: " + error, "red");
                }
            }

        },
        async saveEditedCustomer() {

            if (this.$refs.form.validate()) {

                const customerJSON = JSON.stringify({ customer: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/customers/edit_customer";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", customerJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת לקוח" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("customerEdited", this.itemEdited, "הלקוח עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit customer: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    created() {
        if (this.formTitle === "ערוך לקוח") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            const foundStation = this.stations.find(station => station.hebrewName === this.itemEdited.shop_station);
            this.itemEdited.shop_station = foundStation.name
            this.itemEdited.status = this.itemEdited.status === "פעיל" ? true : false;
            // this.itemEdited.payment_status = this.itemEdited.payment_status === "בתהליך" ? "in_progress" : this.itemEdited.payment_status === "שולם ידנית" ? "paid_manually" : "paid";
            }
            this.role = Auth.getUserRole();
    },
}
</script>
<style></style>
  