<template>
    <v-container class="container2" style="background-color: #d6e4ec;">
        <v-row class="d-flex justify-center row1">
            <v-col cols='12'>
                <v-card elevation="0" class="card1 overflow-auto scroll-container" style="background-color: #d6e4ec; direction: ltr">

                    <div class="container1">
                        <div class="end-aligned-div">
                            <div class="user-info" style="display: flex; justify-content: center; align-items: center;">
                                <span class="mr-2" style="font-weight: 500; font-size: small; color:#34a5e6">{{ userName
                                }}</span>
                                <span class="mr-2" style="font-size: small; color:#34a5e6">{{ email }}</span>
                            </div>
                            <div class="logout-button-container">
                                <v-btn small outlined color="#0e81c4" dark class="mb-2 ms-2" style="max-width: 20px;"
                                    @click="logOut()">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" dark>
                                                mdi-logout-variant
                                            </v-icon>
                                        </template>
                                        <span>יציאה</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                        </div>

                        <div class="centered-div">
                            <v-btn @click="openAccountDetails()">
                                <span style="font-weight: 500; font-size: x-large; color:#34a5e6">אזור אישי</span>
                            </v-btn>
                        </div>
                    </div>

                    <div class="divider"></div>
                    <v-card-text>
                        <!-- <div class="centered-div">
                            <span class="mr-2" style="font-weight: 800; font-size: x-large; color:#5C6BC0">טבלאות</span>
                        </div>
                        <div class="divider" style="padding-bottom: 30px;"></div> -->

                        <div style="padding-bottom: 20px; max-height: 400px;">
                            <v-row>
                                <v-col cols="12" sm="6" xl="12" lg="12" v-for="item in tables" :key="item.id">
                                    <a>
                                        <v-card class="mx-2 card-style" @click="showTable(item.name)">
                                            <span
                                                style="font-weight: bold; font-size: larger; text-align: center; color:#34a5e6">
                                                {{ item.name }}
                                            </span>
                                        </v-card>
                                    </a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
    props: {
        tableListArray: Array,
    },
    components: {
        SnackBar
    },
    data: () => ({
        userName: "ffff",
        email: "grgrgegrg",
        tables: []
    }),
    methods: {
        showTable(name) {
            this.$emit("showTable", name);
        },
        getUserDetails() {
            this.userName = Auth.getUserName();
            this.email = Auth.getUserEmail();
        },
        logOut() {
            localStorage.removeItem("token");
            this.$router.replace({ name: "login" })
        },
        openAccountDetails() {
            this.$emit("showAccountDetails");
        }
    },
    mounted() {
        this.tables = this.tableListArray;
        this.getUserDetails();
    },
}
</script>
<style scoped>
@media (min-width: 1600px) {
    .container2 {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 300px !important;
        background-color: #d6e4ec !important;
    }

    .row1 {
        height: 100%;
    }

    .card1 {
        height: 100%;
    }

    .centered-div {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }

    .end-aligned-div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 20px;

    }
}

@media (max-width: 1600px) {

    .container1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .centered-div {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }

    .end-aligned-div {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        padding-top: 10px;
    }
}

@media (max-width: 500px) {

    .container1 {
        flex-direction: column;
    }

    .centered-div {
        margin-top: 10px;
    }
}

.divider {
    border-top: 0.5px solid #cfcccc;
    /* Set the border style, width, and color */
    margin-top: 10px;
    /* Optional: Add margin to adjust spacing */
    margin-bottom: 10px;
    /* Optional: Add margin to adjust spacing */

}

.user-info {
    display: flex;
    flex-direction: column;
}

.user-info span {
    margin-right: 2px;
}

.logout-button-container {
    display: flex;
    align-items: center;
}

.card-style {
    max-width: 200px !important;
    height: 70px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

/* .scroll-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
} */

.scroll-container::-webkit-scrollbar {
    width: 6px;
    background-color: #eee;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #aca7a7; /* Sets the thumb color to blue */
  border-radius: 3px; /* Optional: Adds rounded corners to the thumb */
}
</style>
  