<template>
    <v-dialog v-model="openMode" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.barcode" label="מקט" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.product_name" label="שם המוצר" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.price" label="מחיר" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.quantity" label="כמות" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station1" label="מגבלה תחנה ירושלים - שמואל הנביא" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station2" label="מגבלה תחנה ירושלים - גוש שמונים" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station3" label="מגבלה תחנה בית שמש - רמה ב" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station4" label="מגבלה תחנה בית שמש - סלונים" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station5" label="מגבלה תחנה אשדוד" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station6" label="מגבלה תחנה קרית גת" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station7" label="מגבלה תחנה הר יונה" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station8" label="מגבלה תחנה 8" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station9" label="מגבלה תחנה 9" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.limit_station10" label="מגבלה תחנה 10" type="number" hide-spin-buttons
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#0e81c4" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#0e81c4" text @click="formTitle === 'ערוך מוצר' ? saveEditedProduct() : saveNewProduct()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            barcode: '',
            name: "",
            quantity: "",
            price: "",
        },
    }),

    methods: {
        async saveNewProduct() {

            if (this.$refs.form.validate()) {


                const productJSON = JSON.stringify({ product: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/products/create_product";
                    const res = await fetch(api, ApiServices.requestOptions("POST", productJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת מוצר" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("productAdded", this.itemEdited, "המוצר נוסף בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved product: " + error, "red");
                }
            }

        },
        async saveEditedProduct() {

            if (this.$refs.form.validate()) {

                const productJSON = JSON.stringify({ product: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/products/edit_product";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", productJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                            this.$emit("productEdited", this.itemEdited, "המשתמש עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit product: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך מוצר") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        }
    },
}
</script>
<style></style>
  