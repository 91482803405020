<template>
  <div>
    <v-row>
      <v-col cols='12' xl='2'>
        <TablesList @showTable="showTable" @showAccountDetails="showAccountDetails()" :tableListArray="tableListArray" />
      </v-col>
      <v-col cols='12' xl='10'>
        <div style="margin-top: 20px;">
          <v-card
            style="width: 400px; max-width: 100%; height: 45px; padding: 5px; margin: 0 auto; border: 1px solid #0e81c4;">
            <div class="center-header">
              <span style="color: #34a5e6!important; font-size: 20px;">מערכת ניהול - חלוקת מוצרי חלב</span>
            </div>
          </v-card>
        </div>
        <UsersTable v-model="usersTable" v-if="usersTable" />

        <AccountDetails v-model="accountDetails" v-if="accountDetails" @Edit="editUserDetails" />
        <AccountEditDetails v-model="accountEditDetails" v-if="accountEditDetails" :userDetails="userDetailsForEdit"
          @Cancle="showAccountDetails" />

        <ProductsTable v-model="productsTable" v-if="productsTable" />

        <CustomersTable v-model="customersTable" v-if="customersTable" />

        <OrdersTable v-model="ordersTable" v-if="ordersTable" />

        <ReportsComp v-model="reportsComp" v-if="reportsComp" />

      </v-col>
    </v-row>

  </div>
</template>
<script>
import TablesList from '@/components/TablesList'
import UsersTable from '@/components/users/UsersTable'
import ProductsTable from '@/components/products/ProductsTable'
import ReportsComp from '@/components/reports/ReportsMenuComp'
import CustomersTable from '@/components/customers/CustomersTable'
import OrdersTable from '@/components/orders/OrdersTable'
import AccountDetails from '@/components/account/UserAccount'
import AccountEditDetails from '@/components/account/UserAccountEdit'
import Auth from '@/services/auth.service.js'

export default {
  name: 'home',
  components: {
    TablesList,
    UsersTable,
    AccountEditDetails,
    AccountDetails,
    ProductsTable,
    CustomersTable,
    OrdersTable,
    ReportsComp,
  },
  data: () => ({
    usersTable: false,
    customerDetailsPage: false,
    accountEditDetails: false,
    accountDetails: false,
    productsTable: false,
    reportsComp: false,
    ordersTable: false,
    customersTable: false,
    userDetailsForEdit: {},
    customerIdForEdit: "",
    tableListArray: [
      {
        name: "משתמשים"
      },
      {
        name: "לקוחות"
      },
      {
        name: "מלאי"
      },
      {
        name: "הזמנות"
      },
      {
        name: "דוחות"
      }
    ],
  }),
  methods: {
    showTable(name) {
      if (name == 'משתמשים') {
        this.usersTable = true;
        this.accountDetails = false;
        this.ordersTable = false;
        this.productsTable = false;
        this.customerDetailsPage = false;
        this.customersTable = false;
        this.accountEditDetails = false;
        this.reportsComp = false;
      } else if (name == 'מלאי') {
        this.productsTable = true;
        this.customersTable = false;
        this.ordersTable = false;
        this.customerDetailsPage = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.accountEditDetails = false;
        this.reportsComp = false;
      } else if (name == 'לקוחות') {
        this.customersTable = true;
        this.productsTable = false;
        this.usersTable = false;
        this.ordersTable = false,
          this.customerDetailsPage = false,
          this.accountDetails = false;
        this.accountEditDetails = false;
        this.reportsComp = false;
      } else if (name == 'הזמנות') {
        this.ordersTable = true;
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      } else if (name == 'דוחות') {
        this.reportsComp = true;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
      }
    },
    showAccountDetails() {
      this.accountDetails = true;
      this.usersTable = false;
      this.productsTable = false;
      this.customersTable = false;
      this.accountEditDetails = false;
      this.ordersTable = false;
      this.customerDetailsPage = false;
      this.reportsComp = false;
    },
    editUserDetails(user) {
      this.userDetailsForEdit = user;

      this.accountEditDetails = true;
      this.customersTable = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.customerDetailsPage = false;
      this.ordersTable = false;
      this.reportsComp = false;
    },
    backToCustomers() {
      this.customersTable = true;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.ordersTable = false;
      this.accountEditDetails = false;
      this.customerDetailsPage = false;
      this.reportsComp = false;
    },
  },
  created() {
    let auth = Auth.checkAuth();
    let role = Auth.getUserRole();
    if (role === "admin") {
      this.usersTable = true;
    } else if (role === "user") {
      this.customersTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name !== "משתמשים");
    } 
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
.center-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>