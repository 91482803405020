<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.username" label="username"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" type="number" hide-spin-buttons
                                    label="פלאפון"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.email" label="אימייל"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="passObj.password" label="סיסמה" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="passObj.repeat_password" label="אימות סיסמה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.status" outlined dense label="סטטוס">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="roles" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.role" outlined dense label="הרשאות"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="permissions" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.permission" outlined dense label="הרשאות"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="stations" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.station" multiple outlined dense label="צפייה בתחנה"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#0e81c4" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#0e81c4" text @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        stations: Array,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            phone: '',
            name: "",
            email: "",
            username: "",
            status: true,
            role: "",
            station: []
        },
        passObj: {
            password: "",
            repeat_password: "",
        },
        status: [{ hebrewName: "פעיל", name: true }, { hebrewName: "מושבת", name: false }],
        roles: [
            { hebrewName: "אדמין", name: "admin" },
            { hebrewName: "משתמש", name: "user" },
        ],
        permissions: [
            { hebrewName: "צפייה ועריכה", name: "viewAndEdit" },
            { hebrewName: "צפייה בלבד", name: "view" },
        ],
    }),

    methods: {
        async saveNewUser() {

            console.log(this.itemEdited.station);
            if (this.$refs.form.validate()) {

                if (this.passObj.length < 6 || this.passObj.password.length > 20) {
                    return this.showSnackBar("הסיסמה חייבת לכלול לפחות 6 תווים ולא יותר מ 20", "red");
                }
                if (this.passObj.password !== this.passObj.repeat_password) {
                    return this.showSnackBar("נא לאמת את הסיסמה", "red");
                }
                if (this.itemEdited.username.length < 5) {
                    return this.showSnackBar("username חייב לכלול לפחות 5 תווים", "red");
                }

                const userJSON = JSON.stringify({ user: this.itemEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/users/create_user";
                    const res = await fetch(api, ApiServices.requestOptions("POST", userJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("userAdded", this.itemEdited, "המשתמש נוצר בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                }
            }

        },
        async saveEditedUser() {

            if (this.$refs.form.validate()) {

                if (this.itemEdited.username.length < 5) {
                    return this.showSnackBar("username חייב לכלול לפחות 5 תווים", "red");
                }

                if (this.passObj.password) {
                    if (this.passObj.password.length < 6 || this.passObj.password.length > 20) {
                        return this.showSnackBar("הסיסמה חייבת לכלול לפחות 6 תווים ולא יותר מ 20", "red");
                    }
                    if (this.passObj.password !== this.passObj.repeat_password) {
                        return this.showSnackBar("נא לאמת את הסיסמה", "red");
                    }
                }

                const userJSON = JSON.stringify({ user: this.itemEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/users/edit_user";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", userJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("userEdited", this.itemEdited, "המשתמש עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit user: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך משתמש") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            console.log(this.itemEdited);
        }
    },
}
</script>
<style></style>
  