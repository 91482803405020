<template>
  <v-container>
    <div style="margin-top: 70px; margin-bottom: 70px;">
      <v-row class="d-flex justify-center">
        <v-col cols='12' lg='10'>
          <v-card style="padding: 1%;">
            <div style="text-align: center; margin-bottom: 40px;">
              <span class="headline">דוח הכנסות</span>
            </div>

            <div style="margin-bottom: 30px; margin-right: 20px;">
              <v-row v-if="progressShow" class="my-5">
                <v-col class="d-flex justify-center">
                  <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" v-for="(item, index) in detailsFormatted"
                  style="border: 1px solid #3f51b53f; margin: 10px;">
                  <div class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large; margin-bottom: 10px;">תחנה: {{ item.shop_station }}
                    </span>
                  </div>
                  <div style="margin-top: 20px;" class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large;">סה"כ: {{ item.totalAmount + " ₪" }}</span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4" style="border: 1px solid #3f51b53f; margin: 30px;">
                  <div class="d-flex justify-center" style="align-items: center;">
                    <span style="font-weight: 600; font-size: x-large; margin-bottom: 10px; margin-left: 20px;">סך כל
                      ההכנסות מכל התחנות
                    </span>
                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark @click="downloadExcel()">
                            mdi-file-download
                          </v-icon>
                        </template>
                        <span>הורד לקובץ אקסל</span>
                      </v-tooltip>
                    </v-btn>
                  </div>
                  <div style="margin-top: 20px;" class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large;">סה"כ: {{ allStationsAmount + " ₪" }}</span>
                  </div>
                </v-col>
              </v-row>

            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import Utils from '@/util/utils.js'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      progressShow: false,
      details: [],
      detailsFormatted: [],
      allStationsAmount: 0,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      stations: [],
    };
  },
  methods: {
    async getDetails() {
      try {
        this.details = [];
        this.detailsFormatted = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_3";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("תאריך לא נמצא", "red");
        } else if (res.status === 200) {
          this.details = jsonObject.groupedPayDetails;
          this.detailsFormatted = jsonObject.groupedPayDetails;
          this.stations = jsonObject.stations;
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get details: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    formatDetails() {
      this.allStationsAmount = 0; // Initialize or reset the total amount
      // Assuming this.stations is an array of objects { name: ..., hebrewName: ... }
      const stationNameMap = new Map(this.stations.map(station => [station.name, station.hebrewName]));

      this.details = this.details.map(item => {
        // Sum up the totalAmount for all items
        this.allStationsAmount += item.totalAmount;

        // Map shop_station to its corresponding Hebrew name
        if (stationNameMap.has(item.shop_station)) {
          item.shop_station = stationNameMap.get(item.shop_station);
        }
        return item; // Return the modified item
      });
      if (!Number.isInteger(this.allStationsAmount)) {
        this.allStationsAmount = parseFloat(this.allStationsAmount.toFixed(1));
      }

    },
    downloadExcel() {
      let fullDetails = [];

      this.details.forEach(item => {
        fullDetails.push(...item.payDetails); // Spread syntax to push elements individually
      });
      console.log(fullDetails);

      // Preprocess fullDetails to remove specific fields and convert arrays to strings
      const processedDetails = fullDetails.map(item => {
        const { createdAt, products, ...rest } = item;
        return {
          ...rest,
          products: JSON.stringify(products), // Convert the products array to a JSON string
          createdAt: Utils.getCurrentDate(createdAt)
        };
      });

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(processedDetails);
      XLSX.utils.book_append_sheet(wb, ws, 'Items');

      // Generate an XLSX file
      XLSX.writeFile(wb, `כל התשלומים.xlsx`);
    }
  },
  mounted() {
    this.getDetails();
  },
  computed: {
  },
};
</script>
  
<style scoped></style>
  